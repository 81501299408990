<template>
  <Transition name="fade">
    <div
      v-if="show"
      class="
        fixed
        z-40
        inset-0
        overflow-y-auto
        backdrop-filter backdrop-blur-sm
      "
    >
      <div
        class="
          flex
          px-4
          py-8
          items-center
          min-h-screen
          justify-center
          bg-gray-500 bg-opacity-75
        "
        @click.self="closeOutside"
      >
        <!-- modal box -->
        <div
          class="
            relative
            text-left
            shadow-xl
            bg-white
            rounded-lg
          "
          :class="[width, {'overflow-hidden': overflow_hide}]"
        >

          <div class="relative">
            <!-- title -->
            <div :class="padding" class="relative text-lg font-medium text-gray-900 z-40">
              <slot name="title" class=" pt-4" />

              <app-icon
                name="XIcon"
                v-if="showClose"
                @click.prevent="close"
                class="
                  w-5
                  m-4
                  top-0
                  right-0
                  absolute
                  cursor-pointer
                  text-xl 
                "
                :class="colorClose"
              />
            </div>

            <!-- body -->
            <div :class="paddingBody">
              <slot name="body" />
            </div>

            <!-- action -->
            <div
              class="
                px-4
                py-2.5
                bg-gray-50
                border-t border-gray-200
                rounded-b-lg
                sm:flex sm:flex-row-reverse
              "
              :class="padding"
            >
              <slot name="action" />

              <app-button-outline
                v-if="showCancelButton"
                type="button"
                class="
                  px-4
                  py-2
                  mt-3
                  w-full
                  shadow-sm
                  bg-white
                  rounded-md
                  font-medium
                  inline-flex
                  outline-none
                  justify-center
                  text-gray-700
                  hover:bg-gray-50
                  border border-gray-300
                  sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm
                "
                @click="close"
              >
                {{ textButtonCancel ?? $t("general.cancel") }}
              </app-button-outline>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
export default {
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    showClose: {
      type: Boolean,
      default: true,
    },
    colorClose: {
      type: String,
      default: "text-gray-500 hover:text-gray-700",
    },
    padding: {
      type: String,
      default: "p-3",
    },
    paddingBody: {
      type: String,
      default: "p-3",
    },
    showCancelButton: {
      type: Boolean,
      default: true,
    },
    textButtonCancel: {
      type: String,
      default: null,
    },
    backgroundClose: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: "max-w-lg w-full",
    },
    overflow_hide: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    show(value) {
      if (value) {
        return document.querySelector("body").classList.add("overflow-hidden");
      }
      return document.querySelector("body").classList.remove("overflow-hidden");
    },
  },
  methods: {
    close() {
      document.querySelector("body").classList.remove("overflow-hidden");
      this.$emit("close");
    },
    closeOutside() {
      if (this.backgroundClose) {
        this.close();
      }
    },
  },
  mounted: function () {
    if (this.showClose) {
      document.addEventListener("keydown", (e) => {
        if (e.key === "Escape") {
          this.close();
        }
      });
    }
  },
};
</script>

<style>
.fade-enter-active {
    animation: fade 0.3s;
  }
  
  .fade-leave-active {
    animation: fade 0.2s reverse;
  }
  
  @keyframes fade {
    0% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  }
</style>