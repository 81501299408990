import client from "@/data/base-client";
import WithAuthRepository from "./with-auth-repository";

export default class ProductRepository extends WithAuthRepository {
  constructor() {
    super();
  }

  async indexProduct(business_id, filtersUrl) {
    const response = await client.get(
      "/products/businesses/" + business_id + "/products",
      this.getToken(),
      filtersUrl
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async createOrUpdateProduct(business_id, product) {
    const response =
      product.id == null
        ? await client.post(
            "/products/businesses/" + business_id + "/products",
            product,
            this.getToken()
          )
        : await client.put(
            "/products/businesses/" +
              business_id +
              "/products/" +
              product.id,
              product,
            this.getToken()
          );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async deleteProduct(business_id, product) {
    const response = await client.delete(
      "/products/businesses/" + business_id + "/products/" + product._id,
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return response;
    }

    return response.data;
  }

  async getProductById(business_id, product_id) {
    const response = await client.get(
      "/products/businesses/" + business_id + "/products/" + product_id,
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async getAllUnitLabel() {
    const response = await client.get(
      "/products/all-unit-label",
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  
}
