import client from "@/data/base-client";
import WithAuthRepository from "./with-auth-repository";

export default class TaxRepository extends WithAuthRepository {
  constructor() {
    super();
  }

  async indexTax(business_id, filtersUrl) {
    const response = await client.get(
      "/taxes/businesses/" + business_id + "/taxes",
      this.getToken(),
      filtersUrl
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async createOrUpdateTax(business_id, taxes) {
    var to_submit_taxes = this.$lodash.omitBy(taxes, this.$lodash.isNil);

    const response =
      taxes._id == null
        ? await client.post(
          "/taxes/businesses/" + business_id + "/taxes",
          to_submit_taxes,
          this.getToken()
        )
        : await client.put(
          "/taxes/businesses/" +
          business_id +
          "/taxes/" +
          taxes._id,
          to_submit_taxes,
          this.getToken()
        );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async deleteTax(business_id, taxes) {
    const response = await client.delete(
      "/taxes/businesses/" + business_id + "/taxes/" + taxes._id,
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return response;
    }

    return response.data;
  }

  async getTaxById(business_id, tax_id) {
    const response = await client.get(
      "/taxes/businesses/" + business_id + "/taxes/" + tax_id,
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

}
