<template>
  <app-modal class="rounded-md" :showCancelButton="false" @close="$emit('close')">
    <template v-slot:title>
      <div class="flex justify-between text-base">
        <p>
          {{ tax._id != null ? $t("tax.edit_tax") : $t("tax.add_tax") }}
        </p>
      </div>
    </template>

    <template v-slot:body>
      <div class="space-y-6">
        <div>
          <app-form-input
            id="tax.name"
            name="tax.name"
            type="text"
            v-model="tax.name"
            :labelText="$t('tax.form_add_tax.label_name')"
            :placeholder="$t('tax.form_add_tax.placeholder_name')"
            :errorMessage="errors.name"
          />
        </div>
        <div>
          <app-form-input
            id="tax.rate"
            name="tax.rate"
            type="number"
            :float="true"
            min="0"
            step="0.05"
            v-model="tax.rate"
            :labelText="$t('tax.form_add_tax.label_rate')"
            :placeholder="$t('tax.form_add_tax.placeholder_rate')"
            :errorMessage="errors.rate"
          />
        </div>
        <div>
          <app-form-input
            id="tax.description"
            name="tax.description"
            type="text"
            v-model="tax.description"
            :labelText="$t('tax.form_add_tax.label_description')"
            :placeholder="$t('tax.form_add_tax.placeholder_description')"
            :errorMessage="errors.description"
          />
        </div>
      </div>
    </template>

    <template v-slot:action>
      <app-button class="w-full" 
      @click="onClickAddTax()" 
      :showf70Icon="false"
      :disabled="hasActiveBusiness">
        {{
          tax._id != null
            ? $t("tax.form_add_tax.label_btn_save_tax")
            : $t("tax.form_add_tax.label_btn_add_tax")
        }}
      </app-button>
    </template>
  </app-modal>
</template>

<script>
import appAlertError from "../alert/app-alert-error.vue";
import Business_status from "@/utils/const/business_status";


export default {
  components: appAlertError,
  emits: ["close", "refresh", "success"],
  data() {
    return {};
  },
  computed: {
    errors() {
      return this.$store.getters["taxStore/errors"];
    },

     business() {
      return this.$store.getters["businessStore/business"];
    },

    hasActiveBusiness() {
      if (this.business.business_status_id == null) {
        return false;
      }
      return this.business.business_status_id != Business_status.APPROVED;
    },
    tax() {
      return this.$store.getters["taxStore/tax"];
    },
  },
  methods: {
    async onClickAddTax() {
      const result = await this.$store.dispatch(
        "taxStore/createOrUpdateTax",
        this.tax
      );

      if (this.$lodash.isEmpty(result.errors)) {
        this.$store.dispatch("taxStore/resetTax");

        this.close();

        this.refresh();

        this.returnData(result.data);

        this.$notify(
          {
            group: "success",
            title: this.$t("general.saved"),
          },
          3000
        );
      }
    },

    close() {
      this.$emit("close");
    },

    refresh() {
      this.$emit("refresh", true);
    },

    returnData(data) {
      this.$emit("success", data);
    },
  },
};
</script>
