<template>
  <TransitionRoot as="template" :show="show">
    <Dialog as="div" class="fixed z-40 inset-0" @close="close">
      <div class="sm:text-right text-left min-h-screen backdrop-filter backdrop-blur-sm">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="fixed inset-0 bg-gray-300 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-x-20"
          enter-to="opacity-100 translate-x-0"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-x-0"
          leave-to="opacity-0 translate-x-20"
        >
          <div
            class="
              sm:inline-block
              block
              bg-white
              text-left
              overflow-y-auto
              shadow-xl
              transform
              transition-all
              h-screen
              w-screen
            "
            :class="[width]"
          >
            <DialogTitle as="div" class="flex justify-between p-4">
              <p class="text-md leading-6 font-medium text-gray-900">
                {{ title }}
              </p>
              <app-icon
                name="XIcon"
                class="w-5 cursor-pointer"
                @click="close"
              />
            </DialogTitle>
            <hr />
            <div>
              <slot name="body" />
            </div>
            <div class="p-4">
              <slot name="action" />
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";

export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    show: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: "md:w-2/3 lg:w-1/2 xl:w-5/12",
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    onClick(value) {
      this.$emit("onClick", value);
    },
  },
};
</script>