<template>
  <div class="password flex flex-col">
    <div class="relative">
      <div class="flex flex-row justify-between">
        <label
          v-if="showlabel"
          :for="name"
          class="block text-sm font-light text-gray-700"
        >
          {{ labelText }}
        </label>
        <app-link :to="{ name: 'forgot-password' }" v-if="showForgotPassword">
          {{ $t("auth.login.forgot_password") }}
        </app-link>
      </div>
      <input
        :type="passwordFieldType"
        class="
          w-full
          mt-2
          pr-10
          h-10
          rounded-md
          shadow-sm
          focus:ring-primary
          border-none
          ring-1 ring-bordercolor
          focus:ring-2 focus:outline-none
          text-sm
        "
        :class="[
          errorMessage.length > 0 ||
          (showError &&
            !(
              min8CharactersPassword &&
              uppercasePassword &&
              specialCharactersPassword
            ))
            ? 'ring-error'
            : 'ring-bordercolor',
        ]"
        :id="id"
        :name="name"
        :autocomplete="autocomplete"
        :disabled="loading"
        :required="required"
        @input="passwordTextChange($event.target.value)"
      />
      <app-icon-password-show
        class="absolute top-0 right-0 m-3 mt-10"
        v-if="showPassword"
        @click="togglePassword"
      />
      <app-icon-password-hide
        class="absolute top-0 right-0 m-3 mt-10"
        v-if="!showPassword"
        @click="togglePassword"
      />
    </div>
    <span v-if="errorMessage.length > 0" class="text-xs text-error mt-1">
      {{ errorMessage[0] }}
    </span>
    <transition name="bounce">
      <div v-if="passwordstrength && haveText" class="flex flex-col m-2 mb-0">
        <p class="text-sm text-gray-700">
          {{ $t("auth.register.password_strength_title") }}
        </p>
        <div class="inline-flex">
          <app-icon
            name="CheckCircleIcon"
            v-if="uppercasePassword"
            class="h-4 w-4 text-success mr-1"
            :class="[uppercasePassword ? 'visible' : 'invisible']"
          />
          <app-icon
            name="ExclamationIcon"
            v-if="showError && !uppercasePassword"
            class="h-4 w-4 text-error mr-1 mt-0.5"
          />
          <p
            class="text-sm text-gray-700"
            :class="[
              showError && !uppercasePassword
                ? 'text-error font-medium'
                : uppercasePassword
                ? 'text-success font-medium'
                : 'text-gray font-light',
            ]"
          >
            {{ $t("auth.register.password_strength_upper_case") }}
          </p>
        </div>

        <div class="inline-flex">
          <app-icon
            name="CheckCircleIcon"
            v-if="specialCharactersPassword"
            class="h-4 w-4 text-success mr-1"
            :class="[specialCharactersPassword ? 'visible' : 'invisible']"
          />
          <app-icon
            name="ExclamationIcon"
            v-if="showError && !specialCharactersPassword"
            class="h-4 w-4 text-error mr-1 mt-0.5"
          />
          <p
            class="text-sm text-gray-700"
            :class="[
              showError && !specialCharactersPassword
                ? 'text-error font-medium'
                : specialCharactersPassword
                ? 'text-success font-medium'
                : 'text-gray font-light',
            ]"
          >
            {{
              $t("auth.register.password_strength_special_char", {
                character: "-!@#$%^&*()",
              })
            }}
          </p>
        </div>
        <div class="inline-flex">
          <app-icon
            name="CheckCircleIcon"
            v-if="min8CharactersPassword"
            class="h-4 w-4 text-success mr-1"
            :class="[min8CharactersPassword ? 'visible' : 'invisible']"
          />
          <app-icon
            name="ExclamationIcon"
            v-if="showError && !min8CharactersPassword"
            class="h-4 w-4 text-error mr-1 mt-0.5"
          />
          <p
            class="text-sm text-gray-700"
            :class="[
              showError && !min8CharactersPassword
                ? 'text-error font-medium'
                : min8CharactersPassword
                ? 'text-success font-medium'
                : 'text-gray font-light',
            ]"
          >
            {{ $t("auth.register.password_strength_min_8") }}
          </p>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      haveText: false,
      showPassword: false,
      passwordFieldType: "password",
      uppercasePassword: false,
      specialCharactersPassword: false,
      min8CharactersPassword: false,
    };
  },
  props: {
    id: {
      type: String,
    },
    name: {
      type: String,
    },
    type: {
      type: String,
    },
    autocomplete: {
      type: String,
    },
    labelText: {
      type: String,
    },
    showlabel: {
      type: Boolean,
      default: true,
    },
    modelValue: {
      type: String,
    },
    required: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    passwordstrength: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: Array,
      default: () => [],
    },
    showError: {
      type: Boolean,
      default: false,
    },
    showForgotPassword: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    togglePassword: function () {
      this.showPassword = !this.showPassword;
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
    passwordTextChange: function (newText) {
      this.haveText = newText.trim().length > 0;
      this.min8CharactersPassword = newText.length >= 8;
      this.uppercasePassword = /[A-Z]/.test(newText);
      this.specialCharactersPassword =
        /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(newText); // eslint-disable-line
      // ;
      this.$emit(
        "passwordValidated",
        this.min8CharactersPassword &&
          this.uppercasePassword &&
          this.specialCharactersPassword
      );
      this.$emit("update:modelValue", newText);
    },
  },
};
</script>
<style scoped>
.bounce-enter-active {
  animation: bounce-in 0.7s;
}
.bounce-leave-active {
  animation: bounce-in 0.7s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
</style>