<template>
  <button
    ref="btnRef"
    @click="onClick"
    type="button"
    v-show="show"
    v-on:mouseenter="disabled || separator ? toggleTooltip() : null"
    v-on:mouseleave="disabled || separator ? toggleTooltip() : null"
    :class="[
      $attrs.class,
      'p-2 flex',
      { 'bg-purple-50 text-primary font-semibold border-primary': active },
      rounded ? 'rounded-md' : 'rounded-none',
      disabled || separator
        ? 'text-gray-400'
        : 'cursor-pointer text-gray-500 hover:bg-gray-100 hover:border-primary',
      { 'cursor-not-allowed': disabled },
      fullWidth ? 'w-full' : 'w-auto',
      separator ? 'text-xs cursor-default pt-3 pb-1' : 'text-sm',
    ]"
  >
    <slot />
  </button>
  <div
    ref="tooltipRef"
    v-bind:class="{ hidden: !tooltipShow, block: tooltipShow }"
    class="
      bg-blue-200
      mb-2
      block
      font-normal
      leading-normal
      text-xs text-center
      no-underline
      break-words
      rounded-lg
    "
  >
    <div class="text-blue-800 p-3">
      {{ toolTipMessage ?? $t("dashboard.feature_access") }}
    </div>
  </div>
</template>

<script>
import { logEvent } from "firebase/analytics";
import { createPopper } from "@popperjs/core";

export default {
  emits: ["onMenuClick"],
  data() {
    return {
      tooltipShow: false,
    };
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "button",
    },
    active: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    separator: {
      type: Boolean,
      default: false,
    },
    eventName: {
      type: String,
      default: null,
    },
    showToolTip: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: true,
    },
    show: {
      type: Boolean,
      default: true,
    },
    toolTipMessage: {
      type: String,
    },
  },
  methods: {
    onClick(event) {
      if (this.disabled) return;

      if (this.eventName != null) {
        logEvent(this.$analytics, "click", { event: this.eventName });
      }

      this.$emit("onMenuClick", event);
    },
    toggleTooltip() {
      if (!this.showToolTip) return;

      if (this.tooltipShow) {
        this.tooltipShow = false;
      } else {
        this.tooltipShow = true;
        createPopper(this.$refs.btnRef, this.$refs.tooltipRef, {
          placement: "bottom",
        });
      }
    },
  },
};
</script>