<template>
  <app-full-modal :title="$t('payment.detail.title_modal')">
    <template v-slot:body>
      <div v-if="loading">
        <app-icon-loading iconColor="primary" class="mx-auto mt-10" />
      </div>

      <div v-else class="w-full">
        <table class="w-full table-fixed">
          <tr v-for="product in invoice.items" :key="product._id">
            <td>
              <p>
                <span class="font-bold">{{
                  product.name ? product.name : product.product.name
                }}</span>
                <span>{{ "(x" + product.quantity + ")" }}</span>
              </p>
            </td>
            <td>
              <p>
                {{
                  `${$formats.currency(product.currency, product.price)}/${
                    product.unit_label
                  }`
                }}
              </p>
            </td>
            <td>
              <p class="text-right">
                {{ `${$formats.currency(product.currency, product.price)}` }}
              </p>
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <p class="text-right">
                {{
                  $t("invoice.create.tax") +
                  (invoice.tax_type
                    ? ` (${$t(`tax.types.${invoice.tax_type}`)}):`
                    : ":")
                }}
              </p>
            </td>
            <td>
              <p class="text-right">
                {{ invoice.tax_name ? invoice.tax_name : "-" }} /
                {{ invoice.tax_rate ? `${invoice.tax_rate}%` : "-" }}
              </p>
            </td>
          </tr>
        </table>
        <div class="w-full mb-2 border-t"></div>
        <table class="w-full table-fixed">
          <tr>
            <td></td>
            <td>
              <p class="text-right">
                {{ $t("invoice.invoice_pdf.subtotal") }}:
              </p>
            </td>
            <td>
              <p class="text-right">
                {{
                  $formats.currency(invoice.currency, invoice.subtotal) ?? "0"
                }}
              </p>
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              <p class="text-right text-gray-400">
                {{
                  `${$t("invoice.create.tax")}${
                    invoice.tax_type
                      ? ` (${$t(`tax.types.${invoice.tax_type}`)})`
                      : ""
                  }:`
                }}
              </p>
            </td>
            <td>
              <p class="text-right text-gray-400">
                {{ invoice.tax_name ? invoice.tax_name : "-" }} /
                {{ invoice.tax_rate ? `${invoice.tax_rate}%` : "-" }}
              </p>
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              <p class="text-right text-gray-400">
                {{
                  `${$t("invoice.create.tax")}${
                    invoice.tax_type
                      ? ` (${$t(`tax.types.${invoice.tax_type}`)})`
                      : ""
                  }:`
                }}
              </p>
            </td>
            <td>
              <p class="text-right text-gray-400">
                {{
                  $formats.currency(invoice.currency, invoice.tax_amount) ?? "0"
                }}
              </p>
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              <p class="text-lg font-bold text-right">
                {{ $t("invoice.invoice_pdf.total_due") }} :
              </p>
            </td>
            <td>
              <p class="text-lg font-bold text-right">
                {{
                  $formats.currency(invoice.currency, invoice.total_amount) ??
                  "0"
                }}
              </p>
            </td>
          </tr>
          <tr>
            <td colspan="3">
              <p class="text-right text-gray-400">
                {{ $t(`invoice.create.types.${invoice.type}`) }}
              </p>
            </td>
          </tr>
        </table>
      </div>
    </template>
  </app-full-modal>
</template>

<script>
export default {
  data() {
    return {
      showModalDetail: false,
      selectWebhookEventLog: null,
    };
  },
  computed: {
    loading() {
      return this.$store.getters["paymentStore/loading"];
    },
    message() {
      return this.$store.getters["paymentStore/message"];
    },
    invoice() {
      return this.$store.getters["invoiceStore/invoice"];
    },
    customer() {
      return this.$store.getters["customerStore/customer"];
    },
    business() {
      return this.masterBusinessMode
        ? this.payment_link?.business
        : this.$store.getters["businessStore/business"];
    },
  },
};
</script>
