import { createStore } from "vuex";
import authStore from "../stores/auth/auth-store";
import userStore from "../stores/user/user-store";
import businessStore from "../stores/business/business-store";
import masterBusinessStore from "../stores/business/master-business-store";
import masterBusinessPaymentStore from "../stores/payment/master-business-payment-store";
import referenceStore from "../stores/reference/reference-store";
import paymentStore from "../stores/payment/payment-store";
import openPaymentLinkStore from "../stores/payment/open-payment-link-store";
import paymentMethodStore from "../stores/payment/payment-method-store";
import integrationStore from "../stores/integration/integration-store";
import walletStore from "../stores/wallet/wallet-store";
import dashboardStore from "../stores/dashboard/dashboard-store";
import mediaStore from "../stores/media/media-store";
import subscriptionStore from "../stores/subscription/subscription-store";
import customerStore from "../stores/customer/customer-store";
import productStore from "../stores/product/product-store";
import taxStore from "../stores/tax/tax-store";
import invoiceStore from "../stores/invoice/invoice-store";

export default createStore({
  state: {
    email_not_verify_error: null,
  },

  getters: {
    email_not_verify_error(state) {
      return state.email_not_verify_error;
    },
  },

  mutations: {
    EmailNotVerifyError(state, error) {
      state.email_not_verify_error = error;
    },
  },

  actions: {
    setGeneralErrors(state, error) {
      if (error?.email_not_verify) {
        state.commit("EmailNotVerifyError", error.email_not_verify);
      } else {
        state.commit("EmailNotVerifyError", null);
      }
    },
  },

  modules: {
    authStore: authStore,
    referenceStore: referenceStore,
    businessStore: businessStore,
    masterBusinessStore: masterBusinessStore,
    masterBusinessPaymentStore: masterBusinessPaymentStore,
    paymentStore: paymentStore,
    paymentMethodStore: paymentMethodStore,
    openPaymentLinkStore: openPaymentLinkStore,
    integrationStore: integrationStore,
    walletStore: walletStore,
    userStore: userStore,
    dashboardStore: dashboardStore,
    mediaStore: mediaStore,
    subscriptionStore: subscriptionStore,
    customerStore: customerStore,
    productStore: productStore,
    taxStore: taxStore,
    invoiceStore: invoiceStore,
  },
});