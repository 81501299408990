import client from "@/data/base-client";
import WithAuthRepository from "./with-auth-repository";

export default class WalletRepository extends WithAuthRepository {
  constructor() {
    super();
  }

  async uploadFile(file) {
    const response = await client.postFile(
      '/media/upload',
      file,
      this.getToken()
    );

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

}
