import MediaRepository from "../../data/repository/media-repository";
import baseStore from "../base-store";
import { logEvent } from "firebase/analytics";

export default {
  namespaced: true,
  state: {
    ...baseStore.state,
  },

  getters: {
    ...baseStore.getters,
  },

  mutations: {
    ...baseStore.mutations,
  },

  actions: {
    async uploadFile(state, data = {}) {
      state.commit("setLoading", true);

      const mediaRepository = new MediaRepository();

      const uploadFileResult = await mediaRepository.uploadFile(data.file);

      if (uploadFileResult.message != null) {
        logEvent(this.$analytics, "upload-file", { result: "failed" });
        state.commit("setErrorMessage", uploadFileResult);
      } else {
        logEvent(this.$analytics, "upload-file", {
          result: "success",
        });
      }

      state.commit("setLoading", false);

      return uploadFileResult;
    },
  },
};
