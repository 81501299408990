<template>
  <Menu as="div" class="relative inline-block text-left h-full"  v-slot="{ open }">
    <MenuButton class="flex items-center h-full z-20">
      <slot :is_open="open" />
    </MenuButton>

    <transition
      enter-active-class="transition duration-100 ease-out"
      enter-from-class="transform scale-95 opacity-0"
      enter-to-class="transform scale-100 opacity-100"
      leave-active-class="transition duration-75 ease-in"
      leave-from-class="transform scale-100 opacity-100"
      leave-to-class="transform scale-95 opacity-0"
    >
      <MenuItems
        class="
          z-20
          absolute
          shadow-md
          rounded-md
          bg-white
          focus:outline-none
          divide-y divide-gray-100
          ring-1 ring-black ring-opacity-5
        "
        :class="[
          margin,
          width,
          classMenuItem,
          {
            '-right-0 origin-top-right': position == 'right',
            '-left-0  origin-top-left': position == 'left',
          },
        ]"
        v-if="hasSlot('menu-header') || hasSlot('menu-items')"
      >
        <div v-if="showArrow" class="relative">
          <div :class="['arrow-' + position]" />
        </div>

        <div class="p-3" v-if="hasSlot('menu-header')">
          <slot name="menu-header" />
        </div>
        <div v-if="hasSlot('menu-items')">
          <slot name="menu-items" />
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>


<script>
import { Menu, MenuButton, MenuItems } from "@headlessui/vue";

export default {
  components: {
    Menu,
    MenuButton,
    MenuItems,
  },
  props: {
    width: {
      type: String,
      default: "w-56",
    },
    classMenuItem: {
      type: String,
      default: "",
    },
    position: {
      type: String,
      default: "right",
    },
    showArrow: {
      type: Boolean,
      default: true,
    },
    margin: {
      type: String,
      default: "mt-4",
    },
  },
  methods: {
    hasSlot(name = "default") {
      return !!this.$slots[name];
    },
  },
};
</script>


<style scoped>
.arrow-right:before {
  content: "";
  display: absolute;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #e6e6e7;
  float: right;
  margin-right: 14px;
  margin-top: -10px;
}

.arrow-right:after {
  content: "";
  display: absolute;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid white;
  float: right;
  margin-right: 14px;
  margin-top: -8px;
}

.arrow-left:before {
  content: "";
  display: absolute;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #e6e6e7;
  float: left;
  margin-left: 14px;
  margin-top: -10px;
}

.arrow-left:after {
  content: "";
  display: absolute;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid white;
  float: left;
  margin-left: 14px;
  margin-top: -8px;
}
</style>

