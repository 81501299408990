export default Object.freeze({
    ADMIN: 1,
    OPERATION: 2,
    SUPPORT: 3,
    ACCOUNT: 4,
    BUSINESS_OWNER: 5,
    BUSINESS_ADMIN: 6,
    BUSINESS_MANAGER: 7,
    BUSINESS_DEVELOPER: 8,
    USER: 9,
    MASTER_BUSINESS_OWNER: 10,
    MASTER_BUSINESS_ADMIN: 11,
    MASTER_BUSINESS_MANAGER: 12,
    MASTER_BUSINESS_DEVELOPER: 13,
    BUSINESS_SUPPORT: 14,
    MASTER_BUSINESS_SUPPORT: 15
  })