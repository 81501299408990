<template>
  <tr class="hover:bg-gray-50 cursor-pointer" :class="{ editing: !item._id }">
    <td>
      <div v-if="item._id" class="flex flex-row justify-center">
        <app-image
          v-if="productPhoto"
          :src="productPhoto"
          class="rounded-md flex-none w-12"
        />
        <img
          v-else
          class="w-12"
          src="@/assets/image/default_product_image.svg"
        />
      </div>
      <div v-else>
        <app-button-upload-image
          :category="MEDIA_CATEGORY.PHOTO"
          :aspectRatio="1"
          @onTempUpload="handlePhotoUploaded"
          :preview="productPhoto"
          width="w-10"
          height="h-10"
          class="items-center"
        />
      </div>
    </td>
    <td>
      <div v-if="item._id">{{ item.name }}</div>
      <div v-else class="product-field product-name">
        <app-form-input
          name="product.name"
          type="text"
          :showlabel="false"
          :placeholder="$t('product.modal.name_placeholder_short')"
          v-model="item.name"
          :errorMessage="errors.name"
        />
      </div>
    </td>
    <td>
      <div v-if="item._id">
        {{
          `${$formats.currency(item.currency, item.price)}/${item.unit_label}`
        }}
      </div>
      <div v-else class="product-field flex flex-col items-end space-y-1">
        <app-form-input-with-select
          class="w-full"
          type="number"
          step=".01"
          :showlabel="false"
          :placeholder="$t('product.modal.price_placeholder')"
          maxLength="7"
          :options="[
            {
              id: 1,
              desc: 'MYR',
            },
          ]"
          v-model="item.price"
          :errorMessage="errors.price"
        />
        <div class="flex flex-col w-full items-end">
          <div
            class="flex flex-row items-center rounded-md"
            :class="[errors.unit_label?.length ? 'ring-1 ring-error px-2' : '']"
          >
            <div>{{ $t("product.modal.unit_placeholder_prefix") }}</div>
            <select
              class="outline-none border-none focus:ring-0 p-0 text-sm"
              v-model="item.unit_label"
            >
              <option value="-" disabled hidden>
                {{ $t("product.modal.unit_placeholder_short") }}
              </option>
              <option v-for="unit in unitLabel" :value="unit.id" :key="unit.id">
                {{ unit.name }}
              </option>
            </select>
          </div>
          <span
            v-if="errors.unit_label?.length"
            class="text-xs text-error w-full"
          >
            {{ errors.unit_label[0] }}
          </span>
        </div>
      </div>
    </td>
    <td>
      <div v-if="item.tax_id" class="flex flex-row space-x-1">
        <p>
          {{ `${item.tax_name} (${item.tax_rate}%)` }}
        </p>
        <app-icon
          name="XCircleIcon"
          class="w-4 cursor-pointer"
          @click="$emit('detach-tax', idx)"
        />
      </div>
      <app-button
        v-else
        :primary="false"
        :showf70Icon="false"
        @click="$emit('attach-tax', idx)"
        width=""
        class="gap-2"
      >
        <app-icon name="PlusIcon" class="w-4" />
        {{ $t("invoice.create.add_tax") }}
      </app-button>
    </td>
    <td>
      <app-form-input
        type="number"
        min="1"
        v-model="item.quantity"
        :showlabel="false"
      />
    </td>
    <td :class="{ 'pt-6': !item._id }">
      {{ $formats.currency(item.currency, calculateProductAmount()) }}
    </td>
    <td :class="{ 'pt-6': !item._id }">
      <app-icon
        name="TrashIcon"
        class="w-4 cursor-pointer"
        @click="$emit('remove', idx)"
      />
    </td>
  </tr>
  <tr v-if="!item._id" style="editing-actions">
    <td colspan="7" class="text-right space-x-3">
      <app-button
        :showf70Icon="false"
        :primary="false"
        :loading="loading"
        class="text-gray-500"
        width="w-1/6"
        @click="$emit('remove', idx)"
      >
        {{ $t("general.cancel") }}
      </app-button>
      <app-button
        :showf70Icon="false"
        :loading="loading"
        width="w-1/6"
        @click="submitProduct()"
      >
        {{ $t("general.save") }}
      </app-button>
    </td>
  </tr>
</template>

<style scoped>
tr td {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
tr.editing td {
  vertical-align: top;
}
tr.editing td .product-field {
  min-width: 170px;
}
tr.editing-actions {
  border-top: 0;
}
</style>

<script>
import MEDIA_CATEGORY from "@/utils/const/media_category";
import TAX_TYPE from "@/utils/const/tax_type";

export default {
  props: {
    idx: {
      type: Number,
      default: 0,
    },
  },

  emits: ["attach-tax", "detach-tax", "recalculate", "remove", "submit"],

  data() {
    return {
      MEDIA_CATEGORY,
      photo_url: null,
      errors: {},
    };
  },

  computed: {
    loading() {
      return this.$store.getters["productStore/loading"];
    },
    unitLabel() {
      return this.$store.getters["productStore/unitLabel"];
    },
    invoice() {
      return this.$store.getters["invoiceStore/invoice"];
    },
    item() {
      return this.invoice.items[this.idx];
    },
    productPhoto() {
      const url = this.item?.media?.find(
        (media) => media.collection_name == MEDIA_CATEGORY.PHOTO
      )?.original_url;
      return url && url.startsWith("/")
        ? `${this.$store.$backendURL}${url}`
        : url;
    },
  },

  methods: {
    handlePhotoUploaded(tempUrl) {
      this.photo_url = tempUrl?.media_path;
      this.item.media = [];
      if (tempUrl.media_url != null) {
        this.item.media.push({
          collection_name: MEDIA_CATEGORY.PHOTO,
          original_url: tempUrl.media_url,
        });
      }
    },
    async submitProduct() {
      const result = await this.$store.dispatch(
        "productStore/createOrUpdateProduct",
        {
          name: this.item.name,
          currency: this.item.currency,
          price: this.item.price,
          unit_label: this.item.unit_label,
          photo_url: this.photo_url,
        }
      );
      if (result) {
        Object.assign(this.invoice.items[this.idx], result.data, {
          product_id: result.data._id,
        });
        this.$emit("submit");
      } else {
        this.errors = this.$store.getters["productStore/errors"];
      }
    },
    toPrecision(amount) {
      return Math.round(amount * 100) / 100;
    },
    calculateTax(amount, rate, type) {
      if (type === TAX_TYPE.INCLUSIVE) {
        return this.toPrecision(amount - (amount * 100) / (100 + rate));
      }
      if (type === TAX_TYPE.EXCLUSIVE) {
        return this.toPrecision((amount * rate) / 100);
      }
      return 0;
    },
    calculateProductAmount() {
      let amount = this.toPrecision(this.item.quantity * this.item.price);
      const taxAmount = this.calculateTax(
        this.item.amount,
        this.item.tax_rate,
        this.item.tax_type
      );
      if (this.item.tax_type === TAX_TYPE.EXCLUSIVE) {
        amount += taxAmount;
      }
      if (this.item.amount !== amount) {
        this.item.tax_amount = taxAmount;
        this.item.amount = amount;
        this.$emit("recalculate");
      }
      return this.item.amount;
    },
  },
};
</script>