<template>
  <div class="flex flex-col">
    <div class="flex" :class="{ 'justify-center': center }">
      <div class="inline-flex" :class="{ 'justify-center': center }">
        <input
          type="checkbox"
          :name="name"
          :value="value"
          v-model="checkedValue"
          class="
            mt-1.5
            rounded
            shadow-sm
            focus:border-primary
            focus:ring-primary
            focus:ring-offset-0
            focus:ring-1
          "
          :class="[
            ($attrs.inputclass,
            [errorMessage.length > 0 ? 'border-error' : 'border-bordercolor']),
            [
              disabled
                ? 'text-gray-500 bg-gray-200'
                : 'text-primary focus:border-primary focus:ring-primary',
            ],
          ]"
          @click="onClick"
          :disabled="disabled"
        />
        <span class="ml-2 mt-1 text-sm">
          <slot />
        </span>
      </div>
    </div>

    <span v-if="errorMessage.length > 0" class="text-xs text-error mt-1">
      {{ errorMessage[0] }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
    },
    name: {
      type: String,
    },
    center: {
      type: Boolean,
      default: true,
    },
    modelValue: {},
    value: {},
    errorMessage: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    checkedValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    onClick(event) {
      this.$emit("onClick", event);
    },
  },
};
</script>