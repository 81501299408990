<template>
  <app-button-outline
    class="relative flex flex-col items-center pt-8 pb-8 h-32 space-y-2"
    :disabled="disabled"
  >
    <div
      class="
        absolute
        border border-1
        w-5
        h-5
        rounded-full
        right-3
        top-3
      "
      :class="[{
        'bg-purple-50' : active
      }]"
    >
      <app-icon v-if="active" name="CheckIcon"></app-icon>
    </div>
    <slot />
  </app-button-outline>
</template>


<script>
export default {
  props: {
    id: {
      type: String,
    },
    name: {
      type: String,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    checkedValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
};
</script>
