<template>
  <MenuItem v-slot="{ actived }">
    <button
      v-show="show"
      class="flex w-full text-sm"
      :class="[
        $attrs.class,
        disabled
          ? 'cursor-default text-gray-400'
          : 'text-gray-600 hover:bg-gray-100',
        {
          'bg-gray-100 text-primary font-bold border-primary':
            actived || active,
        },
        rounded ? 'rounded-md' : 'rounded-none',
        fullWidth ? 'w-full' : 'w-auto',
      ]"
      @click="onClick"
    >
      <slot />
    </button>
  </MenuItem>
</template>

<script>
import { MenuItem } from "@headlessui/vue";
import { logEvent } from "firebase/analytics";

export default {
  components: {
    MenuItem,
  },
  emits: ["onMenuClick"],
  props: {
    eventName: {
      type: String,
      default: null,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    show: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    onClick(event) {
      if (this.disabled) return;

      if (this.eventName != null) {
        logEvent(this.$analytics, "click", { event: this.eventName });
      }

      this.$emit("onMenuClick", event);
    },
  },
};
</script>
