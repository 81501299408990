import client from "@/data/base-client";
import WithAuthRepository from "./with-auth-repository";

// @TODO: invoice.id should be remove, but BE is still not consistent returning this field
export default class InvoiceRepository extends WithAuthRepository {
  constructor() {
    super();
  }

  async indexInvoice(business_id, filtersUrl) {
    const url = `/invoices/businesses/${business_id}/invoices`;
    const response = await client.get(url, this.getToken(), filtersUrl);

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async createOrUpdateInvoice(business_id, invoice) {
    var to_submit_invoices = this.$lodash.omitBy(invoice, this.$lodash.isNil);
    to_submit_invoices.custom_attributes =
      to_submit_invoices.custom_attributes.filter((item) => item.key);

    const url = `/invoices/businesses/${business_id}/invoices`;
    const response =
      invoice._id || invoice.id
        ? await client.put(
            `${url}/${invoice._id}`,
            to_submit_invoices,
            this.getToken()
          )
        : await client.post(url, to_submit_invoices, this.getToken());

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async deleteInvoice(business_id, invoice) {
    const url = `/invoices/businesses/${business_id}/invoices/${
      invoice._id || invoice.id
    }`;
    const response = await client.delete(url, this.getToken());

    if (this.$lodash.isEmpty(response.data)) {
      return response;
    }

    return response.data;
  }

  async getInvoiceById(business_id, invoice_id) {
    const url = `/invoices/businesses/${business_id}/invoices/${invoice_id}`;
    const response = await client.get(url, this.getToken());

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async downloadInvoicePdf(business_id, invoice_id) {
    const url = `/invoices/businesses/${business_id}/invoices/${invoice_id}/download-pdf`;
    const response = await client.get(url, this.getToken());

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async getSingleInvoice(invoice_id) {
    const url = `/invoices/${invoice_id}`;
    const response = await client.get(url, this.getToken());

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async getInvoiceHistory(business_id, invoice_id) {
    const url = `/invoices/businesses/${business_id}/invoices/${invoice_id}/history`;
    const response = await client.get(url, this.getToken());

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }

  async sendEmail(business_id, invoice_id) {
    const url = `/invoices/businesses/${business_id}/invoices/${invoice_id}/mail`;
    const response = await client.post(url, [], this.getToken());

    if (this.$lodash.isEmpty(response.data)) {
      return this.errorResponse(response);
    }

    return this.successResponse(response.data);
  }
}
