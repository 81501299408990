<template>
  <app-full-modal
    :title="$t('invoice.detail.invoice_no', { no: invoice.invoice_no })"
  >
    <template v-slot:body>
      <app-modal
        :show="showDeleteConfirmation"
        @close="showDeleteConfirmation = false"
      >
        <template v-slot:title>{{
          $t("invoice.action.delete_invoice")
        }}</template>
        <template v-slot:body>
          <p class="text-sm text-gray-500">
            {{ $t("invoice.action.delete_invoice_desc") }}
          </p>
        </template>
        <template v-slot:action>
          <app-button
            @onClick="deleteInvoice"
            :showf70Icon="false"
            :primary="false"
            type="button"
            class="
              sm:ml-3 sm:w-auto
              bg-error
              hover:bg-error
              text-white
              outline-none
            "
          >
            {{ $t("general.delete") }}
          </app-button>
        </template>
      </app-modal>
      <div class="flex flex-col pt-6 px-5 space-y-7">
        <div class="flex flex-row space-x-6">
          <div v-for="{ id, value } in amounts" :key="id">
            <p class="font-semibold text-gray-400 mb-2">
              {{ $t(`invoice.detail.${id}`) }}
            </p>
            <p class="font-extrabold text-xl">
              {{ $formats.currency(invoice.currency, value) }}
            </p>
          </div>
          <div>
            <p class="font-semibold text-gray-400 mb-2">
              {{ $t("invoice.detail.status_label") }}
            </p>
            <div class="flex flex-row space-x-10">
              <div
                class="px-4 py-1 text-sm rounded-md"
                :class="{
                  'bg-gray-200 text-gray-600':
                    invoice.status == INVOICE_STATUS.CREATED,
                  'bg-blue-100 text-blue-900':
                    invoice.status == INVOICE_STATUS.OPEN,
                  'bg-red-400 text-white':
                    invoice.status == INVOICE_STATUS.OVERDUE,
                  'bg-green-400 text-white':
                    invoice.status == INVOICE_STATUS.PAID,
                  'bg-blue-300 text-blue-600':
                    invoice.status == INVOICE_STATUS.VOID,
                }"
              >
                {{ $t(`invoice.detail.status.${invoice.status}`) }}
              </div>
              <app-button-outline
                v-if="invoice.status === INVOICE_STATUS.PAID"
                @click="sendReceipt"
                padding="px-4 py-1"
              >
                {{ $t("invoice.action.send_receipt") }}
              </app-button-outline>
              <app-button-dropdown v-else>
                <app-button-outline padding="px-4 py-1">
                  {{ $t("general.actions") }}
                  <app-icon name="ChevronDownIcon" class="w-5 h-5 ml-2" />
                </app-button-outline>
                <template #menu-items>
                  <app-button-dropdown-item
                    v-for="item in getActionItems()"
                    :key="item.key"
                    :disabled="item.disable"
                    :show="item.show"
                    class="p-3"
                    @click.stop="handleActionItemClick(item)"
                  >
                    {{ item.title }}
                  </app-button-dropdown-item>
                </template>
              </app-button-dropdown>
            </div>
          </div>
        </div>
        <div class="flex flex-row divide-x">
          <div
            v-for="({ id, value }, idx) in dates"
            :key="id"
            :class="['pr-6', { 'pl-4': idx > 0 }]"
          >
            <p class="font-semibold text-gray-400 mb-2">
              {{ $t(`invoice.detail.${id}`) }}
            </p>
            <p class="text-sm whitespace-pre date">
              {{ value }}
            </p>
          </div>
        </div>
        <div v-if="invoice.internal_memo" class="border rounded-md py-4 px-3">
          <p class="font-semibold text-gray-400 mb-2">
            {{ $t("invoice.create.internal_memo") }}
          </p>
          <p class="text-sm">
            {{ invoice.internal_memo }}
          </p>
        </div>
        <app-invoice-history />
        <div>
          <p class="font-semibold text-lg border-b pb-3 mb-3">
            {{ $t("invoice.detail.invoice_details") }}
          </p>
          <div class="flex flex-col space-y-2 text-slightly-sm">
            <div
              v-for="{ id, value } in invoiceDetails"
              :key="id"
              class="flex flex-row"
            >
              <p class="font-semibold text-gray-400 w-1/4">{{ $t(id) }}</p>
              <p>{{ value || "-" }}</p>
            </div>
            <div
              v-for="({ key, value }, idx) in invoice.custom_attributes"
              :key="idx"
              class="flex flex-row"
            >
              <p class="font-semibold text-gray-400 w-1/4 capitalize">
                {{ key }}
              </p>
              <p class="capitalize">{{ value || "-" }}</p>
            </div>
          </div>
        </div>
        <div>
          <p class="font-semibold text-lg border-b pb-3 mb-3">
            {{ $t("invoice.detail.customer_details") }}
          </p>
          <div class="flex flex-col space-y-2 text-slightly-sm">
            <div
              v-for="{ id, value } in customerDetails"
              :key="id"
              class="flex flex-row"
            >
              <p class="font-semibold text-gray-400 w-1/4">{{ $t(id) }}</p>
              <p>{{ value || "-" }}</p>
            </div>
          </div>
        </div>
        <div>
          <p class="font-semibold text-lg border-b pb-3 mb-3">
            {{ $t("invoice.detail.product_details") }}
          </p>
          <div class="overflow-x-auto">
            <table class="min-w-full">
              <thead class="bg-white font-semibold text-gray-400">
                <tr>
                  <td class="p-1">{{ $t("invoice.detail.product_image") }}</td>
                  <td class="py-1">{{ $t("invoice.detail.product_name") }}</td>
                  <td class="py-1">{{ $t("invoice.detail.product_price") }}</td>
                  <td class="py-1">{{ $t("invoice.detail.product_unit") }}</td>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-divider-gray">
                <tr v-for="item in invoice.items" :key="item.product_id">
                  <td>
                    <app-image
                      v-if="getProductPhoto(item)"
                      :src="getProductPhoto(item)"
                      class="rounded-md flex-none w-12"
                    />
                    <img
                      v-else
                      class="w-12"
                      src="@/assets/image/default_product_image.svg"
                    />
                  </td>
                  <td>{{ item.name }}</td>
                  <td>{{ $formats.currency(item.currency, item.price) }}</td>
                  <td class="capitalize">{{ item.unit_label }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div>
          <p class="font-semibold text-lg border-b pb-3 mb-3">
            {{ $t("invoice.detail.events") }}
          </p>
        </div>
      </div>
    </template>
  </app-full-modal>
</template>

<style scoped>
.date {
  tab-size: 2em;
}
</style>

<script>
import MEDIA_CATEGORY from "@/utils/const/media_category";
import INVOICE_STATUS from "@/utils/const/invoice_status";

export default {
  emits: ['refresh'],
  data() {
    return {
      showDeleteConfirmation: false,
      INVOICE_STATUS,
    };
  },
  computed: {
    loading() {
      return this.$store.getters["invoiceStore/loading"];
    },
    invoice() {
      return this.$store.getters["invoiceStore/invoice"];
    },
    customer() {
      return this.$store.getters["customerStore/customer"];
    },
    customerBillingAddress() {
      return this.$store.getters["customerStore/customerAddress"]("billing");
    },
    customerShippingAddress() {
      return this.$store.getters["customerStore/customerAddress"]("shipping");
    },
    amounts() {
      return [
        { id: "total_amount", value: this.invoice.total_amount || 0 },
        { id: "total_paid", value: this.invoice.paid || 0 },
        {
          id: "amount_due",
          value: (this.invoice.total_amount || 0) - (this.invoice.paid || 0),
        },
      ];
    },
    dueIn() {
      const now = new Date();
      now.setHours(0, 0, 0, 0);
      return this.$moment(this.invoice.payment_term_date).diff(now, "days");
    },
    dates() {
      return [
        {
          id: "created_date",
          value: this.$moment(this.invoice.created_at).format(
            "DD MMMM YYYY\th:mm:ss A"
          ),
        },
        {
          id: "due_date",
          value: this.$moment(
            this.invoice.payment_term_date,
            "YYYY-MM-DD"
          ).format("DD MMMM YYYY"),
        },
        {
          id: "payment_due",
          value: this.$t("invoice.detail.due_in_short", { n: this.dueIn }),
        },
      ];
    },
    invoiceDetails() {
      return [
        {
          id: "invoice.detail.status_label",
          value: this.$t(`invoice.detail.status.${this.invoice.status}_short`),
        },
        {
          id: "invoice.detail.payment_term",
          value: this.$t("invoice.detail.due_in", { n: this.dueIn }),
        },
        {
          id: "invoice.detail.payment_type",
          value: this.$t(`invoice.create.types.${this.invoice.type}`),
        },
        {
          id: "invoice.detail.amount",
          value: this.$formats.currency(
            this.invoice.currency,
            this.invoice.total_amount
          ),
        },
        {
          id: "invoice.create.tax",
          value: this.invoice.tax_type
            ? this.$t(`tax.types.${this.invoice.tax_type}`)
            : "",
        },
        { id: "invoice.create.memo", value: this.invoice.memo },
      ];
    },
    customerDetails() {
      return [
        { id: "invoice.detail.customer_name", value: this.customer.name },
        { id: "invoice.detail.customer_email", value: this.customer.email },
        {
          id: "invoice.detail.customer_billing_address",
          value: this.customerBillingAddress,
        },
        {
          id: "invoice.detail.customer_shipping_address",
          value: this.customerShippingAddress,
        },
        { id: "invoice.detail.customer_phone", value: this.customer.phone_no },
      ];
    },
  },

  methods: {
    getProductPhoto(product) {
      const url = product?.media?.find(
        (media) => media.collection_name == MEDIA_CATEGORY.PHOTO
      )?.original_url;
      return url && url.startsWith("/")
        ? `${this.$store.$backendURL}${url}`
        : url;
    },
    async deleteInvoice() {
      console.log(this.invoice);
      const result = await this.$store.dispatch(
        "invoiceStore/deleteInvoice",
        this.invoice
      );
      if (!this.$lodash.isNil(result)) {
        this.$emit('refresh');
        this.$emit('close');
      }
      this.showDeleteConfirmation = false;
    },
    displayDeleteConfirmation() {
      this.showDeleteConfirmation = true;
    },
    sendReceipt() {
      console.log("@TODO: trigger send receipt");
    },
    getActionItems() {
      const { status } = this.invoice;
      return [
        {
          key: "edit",
          title: this.$t("invoice.action.edit_invoice"),
          show: status === INVOICE_STATUS.CREATED,
        },
        {
          key: "delete",
          title: this.$t("invoice.action.delete_invoice"),
          show: status === INVOICE_STATUS.CREATED,
        },
        {
          key: "resend",
          title: this.$t("invoice.action.resend_invoice"),
          show: status !== INVOICE_STATUS.CREATED,
        },
        {
          key: "change_status",
          title: this.$t("invoice.action.change_status"),
          show: status !== INVOICE_STATUS.CREATED,
        },
      ];
    },
    handleActionItemClick(menu) {
      if (menu.key === "edit") {
        console.log("edit");
      }

      if (menu.key === "delete") {
        return this.displayDeleteConfirmation();
      }

      if (menu.key === "resend") {
        console.log("resend");
      }

      if (menu.key === "change_status") {
        console.log("change_status");
      }
    },
  },
};
</script>