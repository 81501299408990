<template>
  <div>
    <p class="font-semibold text-gray-400 mb-5">
      {{ $t("invoice.detail.history") }}
    </p>
    <app-timeline :items="logs">
      <template #item-icon="{ type }">
        <div class="flex items-center justify-center w-10 h-6">
          <app-icon-small-logo
            v-if="type === INVOICE_STATUS.CREATED"
            class="filter grayscale mb-1.5"
          />
          <app-icon-outline
            v-else-if="iconMapper[type]"
            :name="iconMapper[type].id"
            :class="['w-5 h-5', iconMapper[type].class]"
          />
          <app-icon
            v-else
            name="PaperAirplaneIcon"
            class="w-5 h-5 text-gray-500 transform rotate-90"
          />
        </div>
      </template>
      <template #item-title="item">
        <i18n-t
          v-if="item.type === INVOICE_STATUS.PAID"
          keypath="invoice.detail.status.paid_with_amount"
          tag="span"
        >
          <template v-slot:amount>
            <span class="font-semibold">
              {{ $formats.currency(item.currency, item.amount) }}
            </span>
          </template>
        </i18n-t>
        <span v-else>{{ $t(`invoice.detail.status.${item.type}`) }}</span>
      </template>
      <template #item-content="{ datetime }">
        <span class="whitespace-pre date">
          {{ $moment(datetime).format("DD MMMM YYYY\th:mm:ss A") }}
        </span>
      </template>
    </app-timeline>
  </div>
</template>

<style scoped>
.date {
  tab-size: 2em;
}
</style>

<script>
import INVOICE_STATUS from "@/utils/const/invoice_status";

export default {
  data() {
    return {
      INVOICE_STATUS,
      iconMapper: {
        [INVOICE_STATUS.PAID]: { id: "CheckIcon", class: "text-green-600" },
        [INVOICE_STATUS.OVERDUE]: { id: "ClockIcon", class: "text-red-500" },
        [INVOICE_STATUS.VOID]: {
          id: "ExclamationIcon",
          class: "text-blue-500",
        },
      },
    };
  },
  computed: {
    invoice() {
      return this.$store.getters["invoiceStore/invoice"];
    },
    logs() {
      return this.$store.getters["invoiceStore/invoiceHistory"];
    },
  },
};
</script>