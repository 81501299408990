import PaymentRepository from "../../data/repository/payment-repository";
import baseStore from "../base-store";
import { logEvent } from "firebase/analytics";

const initialPaymentMethod = [];

export default {
    namespaced: true,
    state: {
        ...baseStore.state,

        payment_methods: initialPaymentMethod,
        payment_gateway_applications: [],
    },

    getters: {
        ...baseStore.getters,

        payment_methods(state) {
            return state.payment_methods;
        },

        payment_gateway_applications(state) {
            return state.payment_gateway_applications;
        },
    },

    mutations: {
        ...baseStore.mutations,

        setPaymentMethods(state, payment_methods) {
            state.payment_methods = payment_methods;
        },

        setPaymentGatewayApplications(state, payment_gateway_applications) {
            state.payment_gateway_applications = payment_gateway_applications;
        },
    },

    actions: {

        async getPaymentMethods(state, businessId) {

            const paymentRepository = new PaymentRepository();

            state.commit("setLoading", true);

            const paymentMethodResult = await paymentRepository.getPaymentMethods(
                businessId
            );

            if (paymentMethodResult.message != undefined) {
                logEvent(
                    this.$analytics,
                    "get-payment-method",
                    { result: "failed" }
                );
                state.commit("setErrorMessage", paymentMethodResult);
                return;
            } else {
                logEvent(
                    this.$analytics,
                    "get-payment-method",
                    { result: "success" }
                );
            }

            state.commit("setLoading", false);
            state.commit("setPaymentMethods", paymentMethodResult.data);
            return paymentMethodResult;

        },

        async getPaymentGatewayApplications(state, businessId) {

            const paymentRepository = new PaymentRepository();

            state.commit("setLoading", true);

            const paymentMethodApplicationResult = await paymentRepository.getPaymentGatewayApplications(
                businessId
            );

            if (paymentMethodApplicationResult.message != undefined) {
                logEvent(
                    this.$analytics,
                    "get-payment-method-application",
                    { result: "failed" }
                );
                state.commit("setErrorMessage", paymentMethodApplicationResult);
                return;
            } else {
                logEvent(
                    this.$analytics,
                    "get-payment-method-application",
                    { result: "success" }
                );
            }

            state.commit("setLoading", false);
            state.commit("setPaymentGatewayApplications", paymentMethodApplicationResult.data.data);

            return paymentMethodApplicationResult.data;

        },



        async createOrUpdatePaymentGatewayApplication(state, data) {

            const paymentRepository = new PaymentRepository();

            if (data.business_id == null) {
                return;
            }

            state.commit("setLoading", true);

            const paymentGatewayApplicationResult = await paymentRepository.createOrUpdatePaymentGatewayApplication(
                data.business_id,
                data
            );

            if (paymentGatewayApplicationResult.message != undefined) {
                logEvent(
                    this.$analytics,
                    paymentGatewayApplicationResult.id == null
                        ? "create-payment-gateway-application"
                        : "update-payment-gateway-application",
                    { result: "failed" }
                );
                state.commit("setErrorMessage", paymentGatewayApplicationResult);
                return;
            } else {
                logEvent(
                    this.$analytics,
                    paymentGatewayApplicationResult.id == null
                        ? "create-payment-gateway-application"
                        : "update-payment-gateway-application",
                    { result: "success" }
                );
            }

            state.commit("setLoading", false);
            state.commit("setPaymentGatewayApplications", paymentGatewayApplicationResult.data);
            return paymentGatewayApplicationResult.data;
        },

        async statusConnectStripeAccount(state, data) {

            const paymentRepository = new PaymentRepository();

            state.commit("setLoading", true);

            const paymentGatewayApplicationResult = await paymentRepository.statusConnectStripeAccount(data.business_id, data.payment_gateway_method_application_id);

            if (paymentGatewayApplicationResult.message != undefined) {
                logEvent(this.$analytics, "status-connect-stripe", { result: "failed" });
                state.commit("setErrorMessage", paymentGatewayApplicationResult);
            } else {
                logEvent(this.$analytics, "stauts-connect-stripe", { result: "success" });
            }

            state.commit("setLoading", false);

            return paymentGatewayApplicationResult;
        },

        async connectStripeAccount(state, data) {

            const paymentRepository = new PaymentRepository();

            state.commit("setLoading", true);

            const paymentGatewayApplicationResult = await paymentRepository.connectStripeAccount(data.business_id, data.payment_gateway_method_application_id);

            if (paymentGatewayApplicationResult.message != undefined) {
                logEvent(this.$analytics, "connect-stripe", { result: "failed" });
                state.commit("setErrorMessage", paymentGatewayApplicationResult);
            } else {
                logEvent(this.$analytics, "connect-stripe", { result: "success" });
            }

            state.commit("setLoading", false);

            return paymentGatewayApplicationResult;
        },

    },
};