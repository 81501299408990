<template>
  <div :class="{ flex: inline }">
    <label
      v-if="showLabel && labelText"
      id="listbox-label"
      class="block text-sm font-light text-gray-700"
      :class="inline ? ['mr-3', 'pt-2'] : 'mb-1'"
    >
      {{ labelText }}
    </label>
    <div
      class="relative"
      :class="{ 'w-1/2': inline }"
      v-click-outside="closeDropdown"
    >
      <span class="inline-block w-full">
        <button
          type="button"
          @click="openDropdown"
          aria-haspopup="listbox"
          aria-expanded="true"
          aria-labelledby="listbox-label"
          class="
            w-full
            relative
            h-10
            pr-10
            md:pr-2
            pl-2
            text-sm
            bg-white
            rounded-md
            shadow-sm
            focus:ring-primary
            border-none
            ring-1
            focus:ring-2 focus:outline-none
          "
          :class="[
            $attrs.inputclass,
            errorMessage.length > 0 ? 'ring-error' : 'ring-bordercolor',
            { 'bg-gray-100': disabled, 'mt-2': !inline },
          ]"
          :disabled="disabled"
        >
          <div class="flex items-center space-x-3 h-6">
            <img
              v-if="showIcon && Object.keys(this.selected ?? {}).length > 0"
              :src="selected.icon ?? selected.flag"
              alt=""
              class="flex-shrink-0 h-7 w-7 object-contain"
            />
            <span
              class="block truncate text-sm ml-1 mr-1"
              :class="[
                Object.keys(this.selected ?? {}).length == 0 ||
                this.selected.id == 0
                  ? 'text-gray-500'
                  : 'text-black',
              ]"
            >
              {{
                Object.keys(this.selected ?? {}).length > 0
                  ? selected.name
                  : placeHolderText
              }}
            </span>
          </div>
          <svg
            v-if="loading"
            class="absolute animate-spin right-2 top-2.5 h-5 w-5 text-gray"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              class="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="4"
            ></circle>
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>

          <span
            v-if="!loading"
            class="
              absolute
              inset-y-0
              right-0
              flex
              items-center
              pr-2
              pointer-events-none
            "
          >
            <app-icon-select />
          </span>
        </button>
      </span>

      <span v-if="errorMessage.length > 0" class="text-xs text-error mt-1">{{
        errorMessage[0]
      }}</span>

      <span v-if="serverMessage.length > 0" class="text-xs text-gray-700 mt-1">
        {{
          serverMessage == "$general.message"
            ? $t("general.unable_to_reach_server")
            : serverMessage
        }}
        <a @click="retry" class="text-bluelink">{{
          $t("general.try_again")
        }}</a>
      </span>

      <!-- Select popover, show/hide based on select state. -->
      <div
        v-show="isOpen"
        class="absolute mt-1 w-full rounded-md bg-white shadow-lg z-10"
      >
        <app-form-input
          v-if="allowFilter"
          id="filterText"
          name="filterText"
          autocomplete=""
          class="pl-2 pr-2 sticky"
          inputclass="text-sm p-2 h-9 mb-2 rounded-none border-1 border-bordercolor ring-1 ring-bordercolor focus:ring-2 focus:outline-none"
          :placeholder="$t('general.placeholder_search')"
          v-model="filterText"
        />
        <app-button
          v-if="allowButton"
          :disabled="false"
          :showf70Icon="false"
          :primary="false"
          alignment="start"
          type="button"
          class="w-full border-none gap-2 text-primary items-center"
          @click="onButtonClick"
        >
          <app-icon name="PlusIcon" class="w-4" />
          {{ buttonText }}
        </app-button>
        <ul
          tabindex="-1"
          role="listbox"
          aria-labelledby="listbox-label"
          aria-activedescendant="listbox-item-3"
          class="
            max-h-56
            rounded-md
            py-1
            text-base
            leading-6
            shadow-xs
            overflow-auto
            focus:outline-none
            sm:text-sm sm:leading-5
          "
        >
          <li v-if="showListTitle" class="bg-gray-200 py-2">
            <div class="ml-5">{{ listTitleText }}</div>
          </li>
          <li v-else></li>
          <li
            tabindex="0"
            id="listbox-item-0"
            @click="select(data)"
            role="option"
            v-for="data in temporaryDatas"
            :key="data.id"
            class="
              text-gray-900
              select-none
              relative
              py-2
              pl-3
              pr-9
              cursor-pointer
              hover:text-white hover:bg-primary
              focus:outline-none focus:text-white focus:bg-primary-100
            "
          >
            <div class="flex items-center space-x-3">
              <img
                v-if="showIcon"
                :src="data.icon ?? data.flag"
                alt=""
                class="flex-shrink-0 h-7 w-7 object-contain"
              />

              <span
                class="block truncate"
                v-bind:class="{
                  'font-normal': !isSelected(data),
                  'font-semibold': isSelected(data),
                }"
              >
                {{ data.name }}
              </span>
            </div>

            <span
              v-show="isSelected(data)"
              class="absolute inset-y-0 right-0 flex items-center pr-4"
            >
              <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path
                  fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { logEvent } from "firebase/analytics";
export default {
  props: {
    datas: {
      type: Array,
    },
    modelValue: {
      type: Object,
      default: () => {},
    },
    id: {
      type: String,
    },
    name: {
      type: String,
    },
    eventName: {
      type: String,
      default: null,
    },
    type: {
      type: String,
    },
    autocomplete: {
      type: String,
    },
    labelText: {
      type: String,
    },
    buttonText: {
      type: String,
    },
    listTitleText: {
      type: String,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    showIcon: {
      type: Boolean,
      default: false,
    },
    showListTitle: {
      type: Boolean,
      default: false,
    },
    allowButton: {
      type: Boolean,
      default: false,
    },
    allowFilter: {
      type: Boolean,
      default: true,
    },
    errorMessage: {
      type: Array,
      default: () => [],
    },
    serverMessage: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    inline: {
      type: Boolean,
      default: false,
    },
  },

  emits: ["update:modelValue", "button-click", "valueSelected"],

  data() {
    return {
      isOpen: false,
      temporaryDatas: [],
      filterText: "",
      selectValue: null,
    };
  },

  computed: {
    placeHolderText() {
      return this.placeholder == ""
        ? this.$t("general.please_select")
        : this.placeholder;
    },
    modelValueSelect: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.selectValue = value;
      },
    },
    selected() {
      return this.modelValueSelect ?? this.selectValue;
    },
  },

  methods: {
    isSelected(value) {
      if (this.selected) {
        const idField = value.id ? "id" : "_id";
        return this.selected[idField] === value[idField];
      }
      return false;
    },
    closeDropdown() {
      this.isOpen = false;
    },
    openDropdown() {
      this.temporaryDatas = this.datas;
      this.isOpen = !this.isOpen;
    },
    select(value) {
      this.isOpen = false;
      this.modelValueSelect = value;
      this.$emit("update:modelValue", value);
      this.$emit("valueSelected", value);
    },
    retry() {
      this.$emit("retry");
    },
    onButtonClick(event) {
      if (this.eventName != null) {
        logEvent(this.$analytics, "click", { event: this.eventName });
      }

      this.$emit("button-click", event);
    },
  },

  watch: {
    filterText(newFilterText) {
      if (this.temporaryDatas == null) {
        return;
      }

      if (newFilterText.trim() == "") {
        this.temporaryDatas = this.datas;
        return;
      }

      this.temporaryDatas = this.datas.filter(function (data) {
        return data.name.toLowerCase().includes(newFilterText.toLowerCase());
      });
    },
  },
};
</script>
