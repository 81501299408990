<template>
  <div class="block relative overflow-hidden">
    <transition name="fade">
      <div class="h-full w-full" v-show="showImage">
        <img
          class="h-full w-full reveal object-contain"
          :src="src"
          :alt="alt"
          :data-srcset="dataSrcSet"
          :data-sizes="dataSizes"
          @load="onImageLoaded()"
          @error="onImageError()"
        />
      </div>
    </transition>

    <transition name="fade-out">
      <div
        v-if="showSpinner"
        class="h-full w-full flex top-0 left-0 m-auto right-0 bottom-0 absolute"
      >
        <svg class="circular" viewBox="25 25 50 50">
          <circle
            class="path"
            cx="50"
            cy="50"
            r="20"
            fill="none"
            stroke-width="2"
            stroke-miterlimit="10"
          />
        </svg>
      </div>
    </transition>

    <div
      v-if="labelHover"
      class="
        flex
        top-0
        left-0
        m-auto
        right-0
        bottom-0
        absolute
        opacity-0
        items-center
        bg-gray-900
        hover:opacity-50
      "
    >
      <span class="m-auto text-white">{{ labelHover }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
    },
    preview: {
      type: String,
      default: "",
    },
    alt: {
      type: String,
      default: "",
    },
    dataSrcSet: {
      type: String,
      default: "",
    },
    dataSizes: {
      type: String,
      default: "",
    },
    labelHover: {
      type: String,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loaded: false,
      error: false,
    };
  },

  computed: {
    hasPreviewImage() {
      return this.preview !== "";
    },
    showImage() {
      return this.loaded && !this.error;
    },

    showSpinner() {
      return (this.hasPreviewImage && !this.loaded) || this.loading;
    },

    showError() {
      return this.loaded && this.error;
    },
  },
  methods: {
    onImageLoaded() {
      this.loaded = true;
      this.error = false;
    },
    onImageError() {
      this.loaded = true;
      this.error = true;
    },
  },
};
</script>

<style scoped>
@keyframes reveal {
  0% {
    transform: scale(1.05);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes preview {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.fade-enter-active {
  transition: opacity 1s ease-in-out;
}
.fade-enter-to {
  opacity: 1;
}
.fade-enter {
  opacity: 0;
}
.fade-out-leave-active {
  transition: opacity 250ms ease-in-out;
}
.fade-out-leave-to {
  opacity: 0;
}
.fade-out-leave {
  opacity: 1;
}
.disable-horizontal-center {
  top: unset !important;
  bottom: unset !important;
}
.disable-vertical-center {
  left: unset !important;
  right: unset !important;
}
.circular {
  animation: rotate 2s linear infinite;
  transform-origin: center center;
  display: block;
  margin: 0 auto;
}
.path {
  animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  stroke-dasharray: 1, 200;
  stroke-linecap: round;
  stroke-dashoffset: 0;
}
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}
@keyframes color {
  100%,
  0% {
    stroke: #715698;
  }
  40% {
    stroke: #623f99;
  }
  66% {
    stroke: #5a319a;
  }
  80%,
  90% {
    stroke: #4d1f94;
  }
}
</style>